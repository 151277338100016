import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';
import Chip from '@material-ui/core/Chip';

// import Chip from '@material-ui/core/Chip';
import Seacrch from './search';
import Actions from './actions';

import { actions as projectListActions } from 'redux/buildings/projectList/reducer';
import { getProjectListTableData } from 'redux/buildings/projectList/selector';

import DataTable from 'components/dataTable';

import Button from '@material-ui/core/Button';
import { getStatusFile } from 'redux/reports/selector';
import { requests as reportsRequests } from 'redux/reports/reducer';
import ctn from 'helpers/countries';
import { getCountryProfile } from 'redux/profile/selector';

const formatDate = 'DD/MM/YYYY';
const columns = [
  {
    prop: 'subtype',
    label: 'Tipo',
    format: (value) => {
      console.log(value);
      switch (value.toUpperCase()) {
        case 'RESIDENTIAL':
          return <FormattedMessage id="Small Project" />;

        case 'COMMERCIAL':
          return <FormattedMessage id="Large Project" />;
      }
    },
  },
  { prop: 'data.name', label: 'Proyecto' },
  {
    prop: 'subtype',
    label: 'Cliente',
    format: (value, row) => {
      console.log(row.customer);
      if (value.toUpperCase() === 'RESIDENTIAL') {
        if (row.data.status === 'Cliente') {
          return row.customer.firstname;
        } else {
          return row.customer.companyname;
        }
      }

      return row.data.companyName;
    },
  },
  {
    prop: 'data.status',
    label: 'Estado',
    format: (value, row) => {
      let textColor = '';
      let color = '';
      switch (value.toUpperCase()) {
        case 'PERDIDA':
          color = '#F00';
          textColor = '#FFF';
          break;
        case 'PENDIENTE':
          color = '#999';
          textColor = '#FFF';
          break;
        case 'GANADA':
          if (row.code) {
            color = '#73c968';
            textColor = '#FFF';
          } else {
            color = '#ffaa00';
            textColor = '#FFF';
          }
          break;
        case 'CANCELADA':
          color = '#601490';
          textColor = '#FFF';
          break;
        case 'CLIENTE':
          color = '#c919d6';
          textColor = '#FFF';
          break;
        default:
          color = '#999';
          textColor = '#FFF';
          value = 'Pendiente';
          break;
      }

      return (
        <Chip
          style={{
            backgroundColor: color,
            color: textColor,
            fontWeight: 'bold',
          }}
          label={
            row.code ? (
              <FormattedMessage id="Ganada / Cerrada" />
            ) : (
              <FormattedMessage id={value} />
            )
          }
        />
      );
    },
  },
  { prop: 'data.applications[0].film.name', label: 'Producto' },
  {
    prop: 'created',
    label: 'Creación',
    format: (value, row) => moment(value).format(formatDate),
  },
  {
    prop: 'code',
    label: 'Certificado de garantía',
  },
  // {
  //   prop: 'hasComplaint',
  //   label: 'Reclamo',
  //   format: (value) => (
  //     <Chip
  //       color={value ? 'primary' : 'secondary'}
  //       label={value ? 'Abierto' : 'NO'}
  //     />
  //   ),
  // },
  {
    prop: 'code',
    label: 'Acciones',
    format: (value, row) => <Actions {...{ value, row }} />,
  },
];

const Table = ({ setFilter, downloadFile, country, file, ...props }) => {
  useEffect(() => {
    setFilter();
  }, [setFilter]);

  if (
    country == 'AR' ||
    country == 'BO' ||
    country == 'CE' ||
    country == 'CL' ||
    country == 'CO' ||
    country == 'CR' ||
    country == 'DO' ||
    country == 'EC' ||
    country == 'GT' ||
    country == 'HN' ||
    country == 'JM' ||
    country == 'MX' ||
    country == 'PA' ||
    country == 'PE' ||
    country == 'PY' ||
    country == 'SV' ||
    country == 'UY'
  ) {
    fileName = 'garantia-building.csv';
  } else if (country == 'BR' || country == 'PT') {
    fileName = 'garante-building.csv';
  } else if (country == 'DE') {
    fileName = 'garantie-building.csv';
  } else if (country == 'NL') {
    fileName = 'garantie-building.csv';
  } else {
    fileName = 'warranty-building.csv';
  }

  return (
    <>
      <div className="row">
        <div className="col-xs-6 col-md-6 col-lg-3">
          <Seacrch />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-1 offset-lg-8">
          <Button
            variant="outlined"
            onClick={downloadFile}
            disabled={file.isLoading}
          >
            <FormattedMessage id="Export..." />
          </Button>
        </div>
      </div>
      <div>
        <div className="kt-space-20" />
        <DataTable
          columns={columns}
          onChangePage={(e, v) =>
            setFilter({
              page: v + 1,
            })
          }
          onChangeRowsPerPage={(e) =>
            setFilter({
              page: 1,
              perPage: e.target.value,
            })
          }
          {...props}
        />
      </div>
    </>
  );
};
let fileName;

export const mapStateToProps = (state) => ({
  ...getProjectListTableData(state),
  file: getStatusFile(fileName)(state),
  country: getCountryProfile(state),
});
export const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(projectListActions.setFilter(filter)),
  downloadFile: () =>
    dispatch(
      reportsRequests.fetch({
        report: '/reports/garantias/building',
        fileName,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);

// export const mapDispatchToProps = (dispatch) => ({
//   setFilter: (filter) => dispatch(projectListActions.setFilter(filter)),
// });

// export default connect(getProjectListTableData, mapDispatchToProps)(Table);
