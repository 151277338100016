import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    project: Yup.object().shape({
      name: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
      requirement: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
      postalCode: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un código postal' }),
      ),
      /* needCase: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })), */
    }),
  });
