import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { getDistributorsOptions } from 'redux/distributors/selector';
import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import {
  getSecurityFilmsBuildingOptions,
  getSolarControlFilmsBuildingOptions,
} from 'redux/filmsBuilding/selector';
import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';
import { requests as filmsBuildingRequest } from 'redux/filmsBuilding/reducer';
import { glassTypeOPTs, checkListTypeOPTs } from 'enums/formOtps';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import DatePicker from 'components/datePicker';
import { getUserRole } from 'redux/profile/selector';
import { EUROPE } from 'enums/regions';
import indexOf from 'lodash/indexOf';
import { injectIntl } from 'react-intl';

const newSheet = {
  type: '',
  meters: '',
  distributor: null,
};

const newInstaller = {
  id: null,
  name: '',
};

export const initialValues = {
  glass: {
    type: '',
    checkList: '',
  },
  installDate: null,
  installers: [{ ...newInstaller }],
  solarSheets: [],
  securitySheets: [],
};

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      value: {
        id: -1,
        name: params.inputValue,
      },
      label: (
        <>
          <FormattedMessage id="Agregar A:" /> "{params.inputValue}"
        </>
      ),
      useValue: true,
    });
  }

  return filtered;
};

const autoCompleteProps = {
  clearOnBlur: true,
  freeSolo: true,
  filterOptions,
  getOptionLabel: (option) =>
    option.useValue ? option.value.name : option.label || '',
  renderOption: (option) => option.label,
  parseNewValue: (value) => ({
    value,
    label: value.name,
  }),
};

export const Step3 = ({
  country,
  values,
  errors: { securitySheets, solarSheets },
  prevStep,
  fetchDistributors,
  distributorsOptions,
  fetchUserInstallersRequest,
  userInstallersOptions,
  securityFilmsBuildingOptions,
  solarControlFilmsBuildingOptions,
  fetchFilmsBuildingRequest,
  user,
  intl,
}) => {
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);

  useEffect(() => {
    fetchFilmsBuildingRequest();
  }, [fetchFilmsBuildingRequest]);

  const distributorFiltered = distributorsOptions.filter((distributor) => {
    return distributor.users.some((user) => user.building === true);
  });

  /*  // Eliminar duplicados basados en el id
  const uniqueDistributorFiltered = distributorFiltered.reduce(
    (uniqueDistributors, distributor) => {
      if (!uniqueDistributors.some((d) => d.id === distributor.id)) {
        uniqueDistributors.push(distributor);
      }
      return uniqueDistributors;
    },
    [],
  ); */

  const filteredOptions = distributorFiltered.filter((option) => {
    const isNLorBE = country === 'NL' || country === 'BE';

    if (isNLorBE == true) {
      return (
        !option.label.toLowerCase().includes('3m') &&
        !option.label.toLowerCase().includes('test') &&
        !option.label.toLowerCase().includes('distributor') &&
        !/^[a-z]+distributor/.test(option.label.toLowerCase())
      );
    } else {
      return (
        !option.label.toLowerCase().includes('test') &&
        !option.label.toLowerCase().includes('distributor') &&
        !/^[a-z]+distributor/.test(option.label.toLowerCase())
      );
    }
  });

  return (
    <>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <PorletHeader>
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="Vídrio" />
                    </PorletHeaderTitle>
                  </PorletHeader>
                </div>
                <div className="col-6">
                  <Field
                    name="glass.type"
                    component={Autocomplete}
                    label="Tipo de vídrio *"
                    variant="outlined"
                    options={glassTypeOPTs}
                  />
                </div>
                <div className="col-6">
                  <Field
                    name="glass.checkList"
                    component={Autocomplete}
                    label="Realizo el Glass Check List *"
                    variant="outlined"
                    options={checkListTypeOPTs}
                    helperText={
                      indexOf(EUROPE, user.country) >= 0
                        ? intl.formatMessage({ id: 'WARRANTY_CHECKLIST_NOTE' })
                        : ''
                    }
                  />
                </div>
                {/* <div className="col-12">
                  <Field
                    component={DatePicker}
                    fullWidth
                    name="installDate"
                    label="Fecha de instalación"
                    inputVariant="outlined"
                  />
                </div> */}
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <PorletHeader>
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="Colocación" />
                    </PorletHeaderTitle>
                  </PorletHeader>
                </div>
              </div>
              <FieldArray name="installers">
                {({ push, remove }) => (
                  <>
                    {values.installers.map((_, index) => (
                      <div className="row" key={index}>
                        <div className="col-12">
                          <Field
                            name={`installers[${index}]`}
                            component={Autocomplete}
                            emptyValue={newInstaller}
                            autoCompleteProps={autoCompleteProps}
                            label="Instalador *"
                            helperText="Por favor seleccione la persona que realizó la colocación"
                            variant="outlined"
                            options={userInstallersOptions}
                            placeholder="Seleccionar"
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        {values.installers.length > 1 && (
                          <>
                            <div className="col-12 kt-space-20" />
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="primary" />
                                <FormattedMessage id="Eliminar este instalador" />
                              </Button>
                              <div className="col-12 kt-space-12" />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    {values.installers.length <= 4 && (
                      <>
                        <div className="kt-space-20" />

                        <div className="row">
                          <div className="col-12">
                            <Button
                              size="small"
                              variant="outlined"
                              fullWidth
                              onClick={() => push(newInstaller)}
                            >
                              <FormattedMessage id="Agregar nuevo instalador" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="kt-space-20" />
                  </>
                )}
              </FieldArray>
            </div> */}
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <PorletHeader>
            <PorletHeaderTitle variant="h6">
              <FormattedMessage id="Películas" />
            </PorletHeaderTitle>
          </PorletHeader>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <div className="kt-space-20" />
                  <PorletHeader>
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="Seguridad" />
                    </PorletHeaderTitle>
                  </PorletHeader>
                </div>
              </div>
              <FieldArray name="securitySheets">
                {({ push, remove }) => (
                  <>
                    {values.securitySheets.map((_, index) => (
                      <div className="row" key={index}>
                        <div className="col-12">
                          <Field
                            name={`securitySheets[${index}].type`}
                            component={Autocomplete}
                            label="Película de seguridad *"
                            helperText="Por favor seleccione una película de seguridad"
                            variant="outlined"
                            options={securityFilmsBuildingOptions}
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name={`securitySheets[${index}].meters`}
                            component={TextField}
                            type="text"
                            label="M2 necesarios *"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name={`securitySheets[${index}].distributor`}
                            component={Autocomplete}
                            label="Distribuidor"
                            helperText="Por favor selecione el distribuidor"
                            variant="outlined"
                            options={filteredOptions}
                            placeholder="Seleccionar"
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name={`securitySheets[${index}].run`}
                            component={TextField}
                            type="text"
                            label="Número de RUN *"
                            helperText="Por favor ingresa el número de RUN de la película colocada"
                            variant="outlined"
                            fullWidth
                            placeholder="Ingresar"
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>

                        {values.securitySheets.length > 0 && (
                          <>
                            <div className="col-12 kt-space-20" />
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="primary" />
                                <FormattedMessage id="Eliminar esta película" />
                              </Button>
                              <div className="col-12 kt-space-12" />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    {values.securitySheets.length < 6 && (
                      <>
                        <div className="kt-space-20" />

                        <div className="row">
                          <div className="col-12">
                            <Button
                              size="small"
                              variant="outlined"
                              fullWidth
                              onClick={() => push(newSheet)}
                            >
                              <FormattedMessage id="Agregar nueva película" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    {typeof securitySheets === 'string' && (
                      <div className="row">
                        <div className="col-sm-12">
                          <FormHelperText error={Boolean(securitySheets)}>
                            {securitySheets}
                          </FormHelperText>
                        </div>
                      </div>
                    )}
                    <div className="kt-space-20" />
                  </>
                )}
              </FieldArray>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <div className="kt-space-20" />
                  <PorletHeader>
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="Control Solar / Control Climático" />
                    </PorletHeaderTitle>
                  </PorletHeader>
                </div>
              </div>
              <FieldArray name="solarSheets">
                {({ push, remove }) => (
                  <>
                    {values.solarSheets.map((_, index) => (
                      <div className="row" key={index}>
                        <div className="col-12">
                          <Field
                            name={`solarSheets[${index}].type`}
                            component={Autocomplete}
                            label="Película de control solar *"
                            helperText="Por favor seleccione una película de control solar"
                            variant="outlined"
                            options={solarControlFilmsBuildingOptions}
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name={`solarSheets[${index}].meters`}
                            component={TextField}
                            type="text"
                            label="M2 necesarios *"
                            variant="outlined"
                            fullWidth
                          />
                        </div>

                        <div className="col-12">
                          <Field
                            name={`solarSheets[${index}].distributor`}
                            component={Autocomplete}
                            label="Distribuidor"
                            helperText="Por favor selecione el distribuidor"
                            variant="outlined"
                            options={filteredOptions}
                            placeholder="Seleccionar"
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name={`solarSheets[${index}].run`}
                            component={TextField}
                            type="text"
                            label="Número de RUN *"
                            helperText="Por favor ingresa el número de RUN de la película colocada"
                            variant="outlined"
                            fullWidth
                            placeholder="Ingresar"
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>

                        {values.solarSheets.length > 0 && (
                          <>
                            <div className="col-12 kt-space-20" />
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="primary" />
                                <FormattedMessage id="Eliminar esta película" />
                              </Button>
                              <div className="col-12 kt-space-12" />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    {values.solarSheets.length < 4 && (
                      <>
                        <div className="kt-space-20" />

                        <div className="row">
                          <div className="col-12">
                            <Button
                              size="small"
                              variant="outlined"
                              fullWidth
                              onClick={() => push(newSheet)}
                            >
                              <FormattedMessage id="Agregar nueva película" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    {typeof solarSheets === 'string' && (
                      <div className="row">
                        <div className="col-sm-12">
                          <FormHelperText error={Boolean(solarSheets)}>
                            {solarSheets}
                          </FormHelperText>
                        </div>
                      </div>
                    )}
                    <div className="kt-space-20" />
                  </>
                )}
              </FieldArray>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep}>
            <FormattedMessage id="Atrás" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
    </>
  );
};

export const mapStateToProps = (state, { values: { installers } }) => ({
  distributorsOptions: getDistributorsOptions(state),
  userInstallersOptions: getUserInstallersOptions(state).filter(
    (item) => !installers.find((installer) => installer.id === item.value.id),
  ),
  securityFilmsBuildingOptions: getSecurityFilmsBuildingOptions(state),
  solarControlFilmsBuildingOptions: getSolarControlFilmsBuildingOptions(state),
  user: getUserRole(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
  fetchFilmsBuildingRequest: () => dispatch(filmsBuildingRequest.fetch()),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Step3));
