import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { EUROPE } from 'enums/regions';

/* const handleDownloadFile = () => {
  const endUserFileUrl = localStorage.getItem('endUserFileUrl');
  if (endUserFileUrl) {
    window.open(endUserFileUrl, '_blank');
  } else {
    console.error('End user file URL is not available in localStorage.');
  }
}; */

export const Glass = ({ values }) => {
  /*  const isEuropeCountry = EUROPE.includes(values.data.country); */
  return (
    <>
      <div className="row">
        <div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Tipo de vidrio:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.glass', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Realizó el Glass Checklist:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.Checklist', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />
          {/* {isEuropeCountry && (
            <div>
              <Button
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={handleDownloadFile}
              >
                <FormattedMessage id="Descargar certificado glass" />
              </Button>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Glass;
