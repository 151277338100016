import * as Yup from 'yup';
import 'utils/phoneValidator';

export default (intl, country) =>
  Yup.object().shape({
    customer: Yup.object().shape({
      firstname: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
      lastname: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un apellido' }),
      ),
      email: Yup.string()
        .email(intl.formatMessage({ id: 'Debe ingresar un email válido' }))
        .required(intl.formatMessage({ id: 'Debe ingresar un email válido' })),
      cellphone: Yup.string()
        .phone(
          intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }),
          country,
        )
        .required(
          intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }),
        ),
      phone: Yup.string().phone(
        intl.formatMessage({ id: 'Debe ingresar un teléfono válido' }),
        country,
      ),
    }),
  });
