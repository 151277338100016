import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import { getCountryProfile } from 'redux/profile/selector';
import { getStatesOptions } from 'redux/states/selector';

import { requests as statesRequest } from 'redux/states/reducer';

import { projectTypeOPTs, requirementTypeOPTs } from 'enums/formOtps';

import { Field } from 'formik';
import Button from '@material-ui/core/Button';

import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import { EUROPE } from 'enums/regions';
import indexOf from 'lodash/indexOf';
import { injectIntl } from 'react-intl';
import { useIntl } from 'react-intl';
import { DropzoneDialog } from 'material-ui-dropzone';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { actions as projectListActions } from 'redux/arqFinishes/projectList/reducer';

import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import { WidgetTab } from 'components/widgetTabs';

const API_URL = window.env.API_URL;
const ACCESS_TOKEN = window.env.ACCESS_TOKEN;

export const initialValues = {
  project: {
    name: '',
    type: '',
    postalCode: '',
    state: '',
    needCase: '',
  },
};
export const Step2 = ({
  prevStep,
  country,
  fetchStates,
  stateOptions,
  setFilter,
  values: {
    project: { state, needCase },
  },
  setFieldValue,
}) => {
  useEffect(() => {
    fetchStates({ country });
  }, [country, fetchStates]);

  useEffect(() => {
    setFilter();
  }, [setFilter]);

  const useStyles = makeStyles((theme) => ({
    input: {
      marginRight: 2,
    },
    errorMessage: {
      color: 'red',
      marginTop: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileInput, setShowFileInput] = useState(false);
  const checkUserCaseStudyOPTs = [
    { label: 'SI', value: 'SI' },
    { label: 'NO', value: 'NO' },
  ];

  const handleProvinceChange = (event, value) => {
    setSelectedProvince(value);
  };

  /* const needCaseStudy = needCase;

  const isEuropeCountry = EUROPE.includes(country);

  const intl = useIntl();
  const [fileName, setFileName] = useState('');
  const handleSave = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      const originalFileName = file.name;
      const newFileName = `end_user-${originalFileName}`;
      setFileName(file.name);

      const blob = new Blob([file], { type: file.type });
      const newFile = new File([blob], newFileName, { type: file.type });

      const data = new FormData();
      data.append('file', newFile);

      try {
        const url = `${API_URL}/bulk/upload`;
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              ACCESS_TOKEN,
            )}`,
          },
        });
        localStorage.setItem('endUserFileUrl', response.data.url);

        setOpen(false);
        toast.success(
          <FormattedMessage id="El archivo se envio correctamente" />,
          toastConfig,
        );
        setFilter();
      } catch (error) {
        setOpen(false);
        toast.error(
          <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
          toastConfig,
        );
        setFilter();
      }
    }
  };

  const endUserFileUrl = localStorage.getItem('endUserFileUrl'); */

  return (
    <>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <PorletHeader>
            <PorletHeaderTitle variant="h6">
              <FormattedMessage id="Proyecto" />
            </PorletHeaderTitle>
          </PorletHeader>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="project.name"
                component={TextField}
                type="text"
                label="Nombre de proyecto (Interior Design for central office)*"
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="project.type"
                component={Autocomplete}
                label="Tipo de proyecto *"
                variant="outlined"
                options={
                  country === 'BE' || country == 'NL'
                    ? projectTypeOPTs.filter(
                        (option) =>
                          option.value !== 'Edificios de oficinas Clase A',
                      )
                    : projectTypeOPTs
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <PorletHeader>
            <PorletHeaderTitle variant="h6">
              <FormattedMessage id="Dirección" />
            </PorletHeaderTitle>
          </PorletHeader>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="project.postalCode"
                component={TextField}
                type="text"
                label="Código Postal *"
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <Field
                name="project.state"
                component={Autocomplete}
                handleChange={() => {
                  setFieldValue('project.city', '');
                }}
                label="Provincia *"
                variant="outlined"
                options={stateOptions}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {isEuropeCountry && (
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-2" />
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="row">
              <div className="col" style={{ width: '315px !important' }}>
                <Field
                  name="project.needCase"
                  component={Autocomplete}
                  label="¿Necesita agregar su caso para un caso de estudio?"
                  variant="outlined"
                  options={checkUserCaseStudyOPTs}
                  fullWidth
                />
              </div>
              <div
                className="col d-flex align-items-center"
                style={{
                  width: '318px !important',
                  marginLeft: '19px',
                  marginTop: '20px',
                }}
              >
                {needCaseStudy === 'SI' && (
                  <div className="w-100">
                    <Button
                      variant="outlined"
                      name="project.file"
                      onClick={() => setOpen(true)}
                      className={`${classes.input} mt-4 w-100`}
                      style={{ width: '318px !important' }}
                      required
                    >
                      <FormattedMessage id="Upload file" />
                    </Button>
                    {fileName && (
                      <span className={classes.fileName}>{fileName}</span>
                    )}
                    <DropzoneDialog
                      showAlerts={false}
                      acceptedFiles={['application/pdf']}
                      cancelButtonText={intl.formatMessage({
                        id: 'Cancel',
                      })}
                      submitButtonText={intl.formatMessage({
                        id: 'Send',
                      })}
                      dialogTitle={intl.formatMessage({
                        id: 'Upload file',
                      })}
                      dropzoneText={intl.formatMessage({
                        id: 'Drag and drop a file here or click',
                      })}
                      maxFileSize={5000000}
                      open={open}
                      onClose={() => setOpen(false)}
                      onSave={handleSave}
                      showPreviews={false}
                      showPreviewsInDropzone={true}
                      showFileNames={true}
                      filesLimit={1}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-2" />
        </div>
      )} */}
      <div className="kt-space-20" />
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 ">
          <div className="row">
            <div className="col-12 submit-bottons">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                /* disabled={needCase === 'SI' && fileName == ''} */
              >
                <FormattedMessage id="Siguiente" />
              </Button>
              <Button variant="outlined" onClick={prevStep}>
                <FormattedMessage id="Atrás" />
              </Button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
    </>
  );
};

export const mapStateToProps = (state) => ({
  country: getCountryProfile(state),
  stateOptions: getStatesOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchStates: (data) => dispatch(statesRequest.fetch(data)),
  setFilter: (filter) => dispatch(projectListActions.setFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Step2));
