import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import get from 'lodash/get';
import { PorletHeaderTitle } from 'components/porletHeader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Button from '@material-ui/core/Button';
import Dialog from '../dialog';

export const Sheets = ({ intl, values }) => {
  const Sheets = get(values, 'data.applications', []) || [];
  const Seguridad = Sheets.filter((sheet) => sheet.film.type === 2);
  const Solar = Sheets.filter((sheet) => sheet.film.type === 1);
  const PPF = Sheets.filter((sheet) => sheet.film.type === 3);
  const Wrap = Sheets.filter((sheet) => sheet.film.type === 4);
  const Subtype = values.subtype;
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      {Boolean(Wrap.length && values.subtype === 'WRAP') && (
        <>
          {console.log('El subtype es:', Subtype)}
          <PorletHeaderTitle variant="h5">
            <FormattedMessage id="Wrapping" />
          </PorletHeaderTitle>
          <div className="kt-space-20" />
          {Wrap.map((sheet, index) => {
            let application = '';
            application += sheet.fullWrap
              ? intl.formatMessage({ id: 'Full Wrap' })
              : '';
            application += sheet.stripes
              ? intl.formatMessage({ id: 'Franjas' })
              : '';
            application += sheet.customDesign
              ? intl.formatMessage({ id: 'Diseño personalizado + Otros' })
              : '';
            return (
              <div className="row" key={index}>
                <div className="col-12 ViewOrEdit">
                  <div>
                    <strong>
                      <FormattedMessage id="Lámina:" />
                    </strong>
                    <span>{sheet.film.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Aplicación:" />
                    </strong>
                    <span>{application}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Número de RUN:" />
                    </strong>
                    <span>{sheet.run}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span>{sheet.distributor.name}</span>
                  </div>
                  {Seguridad.length - 1 !== index && (
                    <div className="kt-space-20" />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
      {Boolean(PPF.length && values.subtype === 'PPF') && (
        <>
          <PorletHeaderTitle variant="h5">
            <FormattedMessage id="PPF" />
          </PorletHeaderTitle>
          <div className="kt-space-20" />
          {PPF.map((sheet, index) => {
            let application = '';
            application += sheet.fullFront
              ? intl.formatMessage({ id: 'Capo completo' })
              : '';
            application += sheet.halfFront
              ? intl.formatMessage({ id: 'Medio Capo' })
              : '';
            application += sheet.sideMirror
              ? intl.formatMessage({ id: 'Cubierta espejos laterales' })
              : '';
            application += sheet.handles
              ? intl.formatMessage({ id: 'Manijas' })
              : '';
            application += sheet.bumpers
              ? intl.formatMessage({ id: 'Paragolpe' })
              : '';
            application += sheet.doorEdges
              ? intl.formatMessage({ id: 'filos de las puertas' })
              : '';
            application += sheet.fenders
              ? intl.formatMessage({ id: 'Guadabarros' })
              : '';
            application += sheet.trunkEdges
              ? intl.formatMessage({ id: 'Filo de maletero- Baul' })
              : '';
            application += sheet.fullCar
              ? intl.formatMessage({ id: 'Auto completo' })
              : '';
            return (
              <div className="row" key={index}>
                <div className="col-12 ViewOrEdit">
                  <div>
                    <strong>
                      <FormattedMessage id="Lámina:" />
                    </strong>
                    <span>{sheet.film.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Aplicación:" />
                    </strong>
                    <span>{application}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Número de RUN:" />
                    </strong>
                    <span>{sheet.run}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span>{sheet.distributor.name}</span>
                  </div>
                  {Seguridad.length - 1 !== index && (
                    <div className="kt-space-20" />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
      {Boolean(Seguridad.length) && (
        <>
          <PorletHeaderTitle variant="h5">
            <FormattedMessage id="Seguridad" />
          </PorletHeaderTitle>
          <div className="kt-space-20" />
          {Seguridad.map((sheet, index) => {
            let application = '';
            application += sheet.front
              ? intl.formatMessage({ id: 'Parabrisas / ' })
              : '';
            application += sheet.back
              ? intl.formatMessage({ id: 'Luneta trasera / ' })
              : '';
            application += sheet.left
              ? intl.formatMessage({ id: 'Lateral izquierdo / ' })
              : '';
            application += sheet.right
              ? intl.formatMessage({ id: 'Lateral derecho / ' })
              : '';
            application += sheet.top
              ? intl.formatMessage({ id: 'Techo solar / ' })
              : '';
            application = application.slice(0, -3);
            return (
              <div className="row" key={index}>
                <div className="col-12 ViewOrEdit">
                  <div>
                    <strong>
                      <FormattedMessage id="Lámina de seguridad:" />
                    </strong>
                    <span>{sheet.film.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Aplicación:" />
                    </strong>
                    <span>{application}</span>
                  </div>
                  <div className="kt-space-10" />
                  <div>
                    <strong>
                      <div className="kt-space-10" />
                      <FormattedMessage className="mt-1" id="Número de RUN:" />
                    </strong>
                    <span className="mt-1">
                      <div className="kt-space-10" />
                      {sheet.run}
                    </span>
                    <span className=" mt-0 ml-3">
                      <Button
                        variant="contained"
                        color="primary"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClick={handleOpenDialog}
                      >
                        <MailOutlineIcon />
                        <FormattedMessage id="Denunciar RUN" />
                      </Button>
                    </span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span>{sheet.distributor.name}</span>
                  </div>
                  {Seguridad.length - 1 !== index && (
                    <div className="kt-space-20" />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
      {Boolean(Seguridad.length) && Boolean(Solar.length) && (
        <div className="kt-space-20" />
      )}
      {Boolean(Solar.length) && (
        <>
          <PorletHeaderTitle variant="h5">
            <FormattedMessage id="Control Solar" />
          </PorletHeaderTitle>
          <div className="kt-space-20" />
          {Solar.map((sheet, index) => {
            let application = '';
            application += sheet.front
              ? intl.formatMessage({ id: 'Parabrisas / ' })
              : '';
            application += sheet.back
              ? intl.formatMessage({ id: 'Luneta trasera / ' })
              : '';
            application += sheet.left
              ? intl.formatMessage({ id: 'Lateral izquierdo / ' })
              : '';
            application += sheet.right
              ? intl.formatMessage({ id: 'Lateral derecho / ' })
              : '';
            application += sheet.top
              ? intl.formatMessage({ id: 'Techo solar / ' })
              : '';
            application = application.slice(0, -3);
            return (
              <div className="row" key={index}>
                <div className="col-12 ViewOrEdit">
                  <div>
                    <strong>
                      <FormattedMessage id="Lámina de seguridad:" />
                    </strong>
                    <span>{sheet.film.name}</span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Aplicación:" />
                    </strong>
                    <span>{application}</span>
                  </div>
                  <div className="kt-space-10" />
                  <div>
                    <strong>
                      <div className="kt-space-10" />
                      <FormattedMessage id="Número de RUN:" />
                    </strong>
                    <span>
                      <div className="kt-space-10" />
                      {sheet.run}
                    </span>
                    <span className=" mt-0 ml-3">
                      <Button
                        variant="contained"
                        color="primary"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClick={handleOpenDialog}
                      >
                        <MailOutlineIcon />
                        <FormattedMessage id="Denunciar RUN" />
                      </Button>
                    </span>
                  </div>
                  <div className="kt-space-20" />
                  <div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span>{sheet.distributor.name}</span>
                  </div>
                  {Solar.length - 1 !== index && (
                    <div className="kt-space-20" />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Instalador:" />
            </strong>
            <span>{values.installer.name}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Código promocional:" />
            </strong>
            <span>{values.promoCode}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Comentario:" />
            </strong>
            <span>{values.comment}</span>
          </div>
          <div className="kt-space-20" />
        </div>
      </div>

      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          close={handleClose}
          code={parseInt(values.id)}
        />
      )}
    </div>
  );
};

export default injectIntl(Sheets);
