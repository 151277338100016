import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { getDistributorsOptions } from 'redux/distributors/selector';
import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import { getfasaraFilmsBuildingOptions } from 'redux/filmsBuilding/selector';
import { requests as distributorsRequest } from 'redux/distributors/reducer';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';
import { requests as filmsBuildingRequest } from 'redux/filmsBuilding/reducer';
import { substrateTypeOPTs, requirementTypeOPTs } from 'enums/formOtps';
import {
  glassFinishesCategories,
  glassFinishesFilms,
  glassFinishesFamilies,
} from 'enums/glassFinishesFilms';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from 'components/form/input';
import Autocomplete from 'components/form/autocomplete';
import DatePicker from 'components/datePicker';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import './previewer.scss';

const newSheet = {
  type: null,
  meters: null,
  distributor: null,
  custom: null,
  glassFinish: null,
  film: null,
  family: null,
  run: null,
};

const newInstaller = {
  id: null,
  name: '',
};

export const initialValues = {
  installDate: null,
  installers: [{ ...newInstaller }],
  fasaraSheets: [],
  family: {},
};

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      value: {
        id: -1,
        name: params.inputValue,
      },
      label: (
        <>
          <FormattedMessage id="Agregar A:" /> "{params.inputValue}"
        </>
      ),
      useValue: false,
    });
  }

  return filtered;
};
function clearCustom(state, value, index) {
  let newValue = '';
  if (value == false) {
    state.fasaraSheets[index].custom = newValue;
  }
}
const autoCompleteProps = {
  clearOnBlur: true,
  freeSolo: true,
  filterOptions,
  getOptionLabel: (option) =>
    option.useValue ? option.value.name : option.label || '',
  renderOption: (option) => option.label,
  parseNewValue: (value) => ({
    value,
    label: value.name,
  }),
};

export const Step3 = ({
  country,
  values,
  errors: { fasaraSheets },
  prevStep,
  fetchDistributors,
  fetchUserInstallersRequest,
  distributorsOptions,
  userInstallersOptions,
  fasaraFilmsBuildingOptions,
  fetchFilmsBuildingRequest,
  intl,
}) => {
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);

  useEffect(() => {
    fetchFilmsBuildingRequest();
  }, [fetchFilmsBuildingRequest]);

  const distributorFiltered = distributorsOptions.filter((distributor) => {
    return distributor.users.some((user) => user.fasara === true);
  });

  /* // Eliminar duplicados basados en el id
  const uniqueDistributorFiltered = distributorFiltered.reduce(
    (uniqueDistributors, distributor) => {
      if (!uniqueDistributors.some((d) => d.id === distributor.id)) {
        uniqueDistributors.push(distributor);
      }
      return uniqueDistributors;
    },
    [],
  ); */

  const filteredOptions = distributorFiltered.filter((option) => {
    const isNLorBE = country === 'NL' || country === 'BE';

    if (isNLorBE == true) {
      return (
        !option.label.toLowerCase().includes('3m') &&
        !option.label.toLowerCase().includes('test') &&
        !option.label.toLowerCase().includes('distributor') &&
        !/^[a-z]+distributor/.test(option.label.toLowerCase())
      );
    } else {
      return (
        !option.label.toLowerCase().includes('test') &&
        !option.label.toLowerCase().includes('distributor') &&
        !/^[a-z]+distributor/.test(option.label.toLowerCase())
      );
    }
  });

  return (
    <>
      {console.log(values)}
      <div className="kt-space-20" />

      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-12">
              <PorletHeader>
                <PorletHeaderTitle variant="h6">
                  <FormattedMessage id="Colocación" />
                </PorletHeaderTitle>
              </PorletHeader>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="col-12">
                <FieldArray name="installers">
                  {({ push, remove }) => (
                    <>
                      {values.installers.map((_, index) => (
                        <div className="row" key={index}>
                          <div className="col-12">
                            <Field
                              name={`installers[${index}]`}
                              component={Autocomplete}
                              emptyValue={newInstaller}
                              autoCompleteProps={autoCompleteProps}
                              label="Instalador *"
                              helperText="Por favor seleccione la persona que realizó la colocación"
                              variant="outlined"
                              options={userInstallersOptions}
                              placeholder="Seleccionar"
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                          {values.installers.length > 1 && (
                            <>
                              <div className="col-12 kt-space-20" />
                              <div className="col-12">
                                <Button
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => remove(index)}
                                >
                                  <DeleteIcon color="primary" />
                                  <FormattedMessage id="Eliminar este instalador" />
                                </Button>
                                <div className="col-12 kt-space-12" />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                      {values.installers.length <= 4 && (
                        <>
                          <div className="kt-space-20" />

                          <div className="row">
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => push(newInstaller)}
                              >
                                <FormattedMessage id="Agregar nuevo instalador" />
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="kt-space-20" />
                    </>
                  )}
                </FieldArray>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="col-12">
                <Field
                  component={DatePicker}
                  fullWidth
                  name="installDate"
                  label={<FormattedMessage id="Fecha de instalación" />}
                  inputVariant="outlined"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="kt-space-20" />
                  <PorletHeader>
                    <PorletHeaderTitle variant="h6">
                      <FormattedMessage id="3M™ Glass Finishes" />
                    </PorletHeaderTitle>
                  </PorletHeader>
                </div>
              </div>
              {/*<div className="row">
                
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="row">
                    <div className="col-12">
                      <Field
                        name="project.need"
                        component={Autocomplete}
                        label="Principal necesidad *"
                        variant="outlined"
                        options={requirementTypeOPTs}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6" />
  </div>*/}
              <div className="kt-space-20" />
              <FieldArray name="fasaraSheets">
                {({ push, remove }) => (
                  <>
                    {values.fasaraSheets.length < 1 ? push(newSheet) : <></>}
                    {values.fasaraSheets.map((_, index) => (
                      <div className="row" key={index}>
                        <div className="col-sm-12 col-lg-6">
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].glassFinish`}
                                component={Autocomplete}
                                translate="false"
                                label="Categoría *"
                                variant="outlined"
                                options={glassFinishesCategories}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].family`}
                                component={Autocomplete}
                                label="Familia *"
                                variant="outlined"
                                translate="false"
                                options={
                                  glassFinishesFamilies.filter(
                                    (e) =>
                                      e.category ==
                                      values.fasaraSheets[index].glassFinish,
                                  ) || 'Custom'
                                }
                              />
                            </div>
                          </div>

                          <div className="Row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].film`}
                                component={Autocomplete}
                                label="Producto *"
                                helperText="Por favor seleccione una película 3M™ Fasara™ "
                                variant="outlined"
                                translate="false"
                                options={
                                  glassFinishesFilms.filter(
                                    (e) =>
                                      e.family ==
                                      values.fasaraSheets[index].family,
                                  ) || 'Custom'
                                }
                                onBlur={clearCustom(
                                  values,
                                  values.fasaraSheets[index].film == 'Custom' &&
                                    values.fasaraSheets[index].family ==
                                      'Custom',
                                  index,
                                )}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].distributor`}
                                component={Autocomplete}
                                label="Distribuidor"
                                helperText="Por favor selecione el distribuidor"
                                variant="outlined"
                                options={filteredOptions}
                                placeholder="Seleccionar"
                                InputLabelProps={{ shrink: true }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`fasaraSheets[${index}].meters`}
                                component={TextField}
                                type="text"
                                label="M2 necesarios *"
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={`dinocSheets[${index}].run`}
                                component={TextField}
                                type="text"
                                label="Número de RUN:*"
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                          <div className="film-previewer">
                            <PorletHeaderTitle variant="h7">
                              <FormattedMessage id="Vista Previa" />
                            </PorletHeaderTitle>
                            <img
                              className="img-previewer"
                              src={
                                values.fasaraSheets[index].film
                                  ? process.env.PUBLIC_URL +
                                    '/media/fasara/' +
                                    values.fasaraSheets[index].film.toString() +
                                    '.jpg'
                                  : process.env.PUBLIC_URL +
                                    '/media/fasara/Choose.jpg'
                              }
                              alt="values.fasaraSheets[index].film"
                            />

                            <Field
                              name={`fasaraSheets[${index}].custom`}
                              component={TextField}
                              type="text"
                              label="Custom 3M™ Glass Finish™"
                              variant="outlined"
                              fullWidth
                              disabled={
                                values.fasaraSheets[index].film == 'Custom' &&
                                values.fasaraSheets[index].family == 'Custom'
                                  ? false
                                  : true
                              }
                              helperText='Por favor complete sólo si eligió una lámina "CUSTOM"'
                            />
                          </div>
                        </div>
                        {values.fasaraSheets.length > 0 && (
                          <>
                            <div className="col-12 kt-space-20" />
                            <div className="col-12">
                              <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="primary" />
                                <FormattedMessage id="Eliminar esta lámina" />
                              </Button>
                              <div className="col-12 kt-space-12" />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    {values.fasaraSheets.length < 6 && (
                      <>
                        <div className="kt-space-20" />

                        <div className="row">
                          <div className="col-12">
                            <Button
                              size="small"
                              variant="outlined"
                              fullWidth
                              onClick={() => push(newSheet)}
                            >
                              <FormattedMessage id="Agregar nueva lámina" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    {typeof fasaraSheets === 'string' && (
                      <div className="row">
                        <div className="col-sm-12">
                          <FormHelperText error={Boolean(fasaraSheets)}>
                            {fasaraSheets}
                          </FormHelperText>
                        </div>
                      </div>
                    )}
                    <div className="kt-space-20" />
                  </>
                )}
              </FieldArray>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-2" />
        <div className="col-sm-12 col-md-12 col-lg-8 submit-bottons">
          <Button variant="contained" type="submit" color="primary">
            <FormattedMessage id="Siguiente" />
          </Button>
          <Button variant="outlined" onClick={prevStep}>
            <FormattedMessage id="Atrás" />
          </Button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-2" />
      </div>
    </>
  );
};

export const mapStateToProps = (state, { values: { installers } }) => ({
  distributorsOptions: getDistributorsOptions(state),
  userInstallersOptions: getUserInstallersOptions(state).filter(
    (item) => !installers.find((installer) => installer.id === item.value.id),
  ),

  //getfasaraFilmsBuildingOptions(state),
});
export const mapDispatchToProps = (dispatch) => ({
  fetchDistributors: () => dispatch(distributorsRequest.fetch()),
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
  fetchFilmsBuildingRequest: () => dispatch(filmsBuildingRequest.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
