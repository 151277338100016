import React from 'react';
import Breadcrumbs from 'components/breadcrumb';
import Layout from '_metronic/layout/Layout';

import From from './form';
const breadcrumb = [
  { page: 'installer/dinoc', title: 'Acabados de Arquitectura' },
  { page: 'installer/dinoc/new/small', title: '3M™ DI-NOC™' },
  { page: 'installer/dinoc/new/small', title: 'Nuevo Proyecto Residencial' },
];

export default () => (
  <Layout>
    <div className="kt-space-20" />
    <div className="row">
      <div className="col-12">
        <div className="kt-space-20" />
        <Breadcrumbs title="3M™ DI-NOC™" items={breadcrumb} />
        <div className="kt-space-20" />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <From />
      </div>
    </div>
  </Layout>
);
