import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    glass: Yup.object().shape({
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
      checkList: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar una opción' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })),
    }),
    // installers: Yup.array().of(
    //   Yup.object().test(
    //     'hasInstaller',
    //     intl.formatMessage({ id: 'Debe ingresar un instalador' }),
    //     (value) => Boolean(value.id),
    //   ),
    // ),
    solarSheets: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          meters: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
          /*distributor: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          run: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),*/
        }),
      )
      .when('$securitySheets', (value, schema) => {
        if (value.length) {
          return schema;
        } else {
          return schema.min(
            1,
            intl.formatMessage({ id: 'Debe agregar una película' }),
          );
        }
      }),
    securitySheets: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          meters: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
        }),
      )
      .when('$solarSheets', (value, schema) => {
        if (value.length) {
          return schema;
        } else {
          return schema.min(
            1,
            intl.formatMessage({ id: 'Debe agregar una película' }),
          );
        }
      }),
  });
