import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { PorletHeaderTitle } from 'components/porletHeader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Button from '@material-ui/core/Button';
import Dialog from '../dialog';
import dataTable from 'components/dataTable';

export const Sheets = ({ values }) => {
  const Films = get(values, 'data.applications', []) || [];

  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      {Boolean(Films.length) && (
        <>
          <PorletHeaderTitle variant="h5">
            <FormattedMessage
              id={
                values.subtype === 'FasaraSmall' || values.subtype === 'Fasara'
                  ? '3M™ Glass Finishes'
                  : '3M™ DI-NOC™'
              }
            />
          </PorletHeaderTitle>
          <div className="kt-space-20" />
          {Films.map((sheet, index) => {
            return (
              <div className="row" key={index}>
                {values.subtype === 'FasaraSmall' ||
                values.subtype === 'Fasara' ? (
                  <div className="col-12 ViewOrEdit">
                    <div>
                      <strong>
                        <FormattedMessage id="Categoría:" />
                      </strong>
                      <span>{sheet.glassFinish}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="Familia:" />
                      </strong>
                      <span>{sheet.family}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="Producto:" />
                      </strong>
                      <span>{sheet.film}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="M2 Necesarios:" />
                      </strong>
                      <span>{sheet.meters}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="Vista Previa" />
                      </strong>
                      <div className="w-50">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/media/fasara/' +
                            sheet.film +
                            '.jpg'
                          }
                          className="img-previewer-summary w-100 "
                        />
                      </div>
                    </div>
                    {/*<div>
                    <strong>
                      <FormattedMessage id="Distribuidor:" />
                    </strong>
                    <span>{sheet.distributor.name}</span>
                  </div>*/}
                    <div className="kt-space-10" />
                    <div>
                      {/*<div className="kt-space-20" />

                    <strong>
                      <div className="kt-space-10" />
                      <FormattedMessage id="Número de RUN:" />
                    </strong>
                    <span>
                      <div className="kt-space-10" />
                      {sheet.run}
                    </span>
                    <span className=" mt-0 ml-3">
                      <Button
                        variant="contained"
                        color="primary"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClick={handleOpenDialog}
                      >
                        <MailOutlineIcon />
                        <FormattedMessage id="Denunciar RUN" />
                      </Button>*/}
                      {/* <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleOpenDialog}>
                          <MailOutlineIcon />
                          <FormattedMessage id="Denunciar RUN" />
                        </MenuItem> 
                      </Menu>*/}
                      {/*</span>*/}
                    </div>
                    {Films.length - 1 !== index && (
                      <div className="kt-space-20" />
                    )}
                  </div>
                ) : (
                  <div className="col-12 ViewOrEdit">
                    <div>
                      <strong>
                        <FormattedMessage id="Sustrato:" />
                      </strong>
                      <span className="w-50">{sheet.substrate}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="Necesidad Principal:" />
                      </strong>
                      <span className="w-50">{sheet.need}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="Familia:" />
                      </strong>
                      <span className="w-50">{sheet.family}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="Producto:" />
                      </strong>
                      <span className="w-50">{sheet.film}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="Distribuidor:" />
                      </strong>
                      <span className="w-50">{sheet.distributor.name}</span>
                    </div>
                    <div className="kt-space-20" />
                    <div>
                      <strong>
                        <FormattedMessage id="M2 Necesarios:" />
                      </strong>
                      <span className="w-50">{sheet.meters}</span>
                    </div>
                    <div className="kt-space-20" />
                    {sheet.custom === '' ? (
                      <div>
                        <strong>
                          <FormattedMessage id="Vista Previa" />
                        </strong>
                        <div className="w-50">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/media/dinoc/' +
                              sheet.film +
                              '.jpg'
                            }
                            className="img-previewer-summary w-100 "
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <strong>
                          <FormattedMessage id="Descripción" />
                        </strong>
                        <span>{sheet.custom}</span>
                      </div>
                    )}
                    <div className="kt-space-20" />
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}

      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          close={handleClose}
          code={parseInt(values.id)}
        />
      )}
    </div>
  );
};

export default Sheets;
