import * as Yup from 'yup';

export default (intl) =>
  Yup.object().shape({
    /* installers: Yup.array().of(
      Yup.object().test(
        'hasInstaller',
        intl.formatMessage({ id: 'Debe ingresar un instalador' }),
        (value) => Boolean(value.id),
      ),
    ), */
    fasaraSheets: Yup.array()
      .of(
        Yup.object().shape({
          film: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          family: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          glassFinish: Yup.string()
            .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
            .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
          distributor: Yup.string()
            .required(
              intl.formatMessage({ id: 'Debe seleccionar un distribuidor' }),
            )
            .typeError(
              intl.formatMessage({ id: 'Debe seleccionar un distribuidor' }),
            ),
          meters: Yup.number()
            .required(intl.formatMessage({ id: 'Debe ingresar un número' }))
            .typeError(intl.formatMessage({ id: 'Debe ingresar un número' })),
          run: Yup.string()
            .required(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            )
            .typeError(
              intl.formatMessage({
                id:
                  'Por favor ingresa el número de RUN de la película colocada',
              }),
            ),
        }),
      )
      .when('$fasaraSheets', (value, schema) => {
        if (value.length) {
          return schema;
        } else {
          return schema.min(
            1,
            intl.formatMessage({ id: 'Debe agregar una lámina' }),
          );
        }
      }),
  });
